import { Json } from "@redotech/json/json";
import { Currency } from "@redotech/money/currencies";
import { zExt } from "@redotech/rpc/ext";
import { isDefined } from "@redotech/util/type";
import * as sumBy from "lodash/sumBy";
import { z } from "zod";
import {
  ReturnSummaryResult,
  ReturnSummaryResultSchema,
} from "./agents/return-summary";
import { ReviewResult, ReviewResultSchema } from "./agents/review-result";
import { Order, Provider } from "./order";
import {
  MaybeOriginOrderArray,
  OriginExchangeProductType as OriginExchangeProductTypeEnum,
  OriginOrder,
} from "./origin-order-utils";
import {
  PaymentIntentItem,
  PaymentIntentItemZodSchema,
} from "./payment-intent";
import {
  PickupLocation,
  ProvisionType,
  Settlement,
  SettlementZodSchema,
} from "./return-flow";
import { ReturnMethod, ReturnMethodZodSchema } from "./return-method";
import { ReturnStatus } from "./return-status";
import {
  Shipment as ShippingProviderShipment,
  ShipmentZodSchema as ShippingProviderShipmentZodSchema,
} from "./shipment";
import { ShopifyExchangeOrderStrategy } from "./shopify";
import {
  Address,
  CustomerPortalVersion,
  ExchangeGroup,
  ExchangeGroupZodSchema,
  Team,
  AddressZodSchema as TeamAddressZodSchema,
} from "./team";
import { TimelineEvent, TimelineEventZodSchema } from "./timeline";
import { ITrackable } from "./trackable";
import { ITrackableZodSchema } from "./trackable-zod";

export { NULL_ORDER_ID } from "./third-party";

export type MultipleChoiceTextAnswer = {
  answer: string;
  questionText: string;
  step: number;
};
export enum ReturnChildWorkflow {
  AGENT_RETURN_SUMMARY = "agentReturnSummary",
}

export interface CustomerSummary {
  first_name?: string | null;
  last_name?: string | null;
  email?: string | null;
  phone_number?: string | null;
}

export interface Fulfillment {
  status: string;
  created?: Date;
}

export const RETURNS_FACET_OPTIONS = [
  "awaiting_disposition",
  ...Object.values(ReturnStatus),
] as const;
export type ReturnsFacetOptions = (typeof RETURNS_FACET_OPTIONS)[number];

export interface ReturnsFacets {
  total: number;
  byReturnFilterFacet: { [T in ReturnsFacetOptions]: number };
}

export interface NeedsActionCounts {
  returns: number;
  claims: number;
}

export const ExchangeItemZodSchema = z.object({
  variantId: z.string(),
  title: z.string(),
  variantTitle: z.string(),
  images: z.array(z.string().nullable()),
  price: z.string(),
  tax: z.string().nullish(),
  itemValue: z.string().nullish(),
  quantity: z.number(),
  attributes: z
    .array(z.object({ key: z.string(), value: z.string() }))
    .nullish(),
  currencyCode: z.string().nullish(),
});

export type ExchangeItemZod = z.infer<typeof ExchangeItemZodSchema>;
export interface ExchangeItem {
  variantId?: string;
  productId?: string;
  title?: string;
  variantTitle?: string;
  images?: string[];
  price?: string;
  tax?: string;
  itemValue?: string;
  quantity?: number;
  attributes?: { key: string; value: string }[];
  outOfStockDuringProcessing?: boolean;

  // FIXME Not contained in the database schema
  currencyCode?: string;
}

export enum ReturnedProductStatus {
  OPEN = "open",
  COMPLETE = "complete",
  // If there is an advanced exchange, we want to wait to process any items until all
  // items have either been processed or rejected, as this will affect all totals.
  // Pending means that the item has been marked as processed but not actually processed
  PENDING = "pending",
  APPROVED = "approved",
  REJECTED = "rejected",
  PRE_SHIPMENT = "pre-shipment",
  IN_REVIEW = "in_review",
}

export function returnedProductStatusToDisplayText(
  status: ReturnedProductStatus,
): string {
  return {
    [ReturnedProductStatus.OPEN]: "Open",
    [ReturnedProductStatus.COMPLETE]: "Complete",
    [ReturnedProductStatus.PENDING]: "Pending",
    [ReturnedProductStatus.APPROVED]: "Approved",
    [ReturnedProductStatus.REJECTED]: "Rejected",
    [ReturnedProductStatus.PRE_SHIPMENT]: "Pre-shipment",
    [ReturnedProductStatus.IN_REVIEW]: "In review",
  }[status];
}

// FIXME This overloads the "ReturnType<T>" TS utility type - replace it with ReturnTypeEnum everywhere
export type ReturnType = "return" | "claim" | "warranty" | "managed_claim";
export enum ReturnTypeEnum {
  CLAIM = "claim",
  RETURN = "return",
  WARRANTY = "warranty",
  MANAGED_CLAIM = "managed_claim",
}

export const RETURN_TYPE_ROUTE = {
  [ReturnTypeEnum.RETURN]: "returns",
  [ReturnTypeEnum.CLAIM]: "claims",
  [ReturnTypeEnum.WARRANTY]: "warranties",
  [ReturnTypeEnum.MANAGED_CLAIM]: "claims",
};

// FIXME This is slightly different than the compensation method enum for draft returns
export enum CompensationMethod {
  REFUND = "refund",
  STORE_CREDIT = "store_credit",
  EXCHANGE = "exchange",
}

export enum InstantRefundStatus {
  PENDING = "pending",
  PAYMENT_FAILED = "payment_failed",
  PAID = "paid",
  PROCESSED = "processed",
  EXPIRED = "expired",
}

export enum GiftCardSource {
  CLIENT = "client",
  EXCHANGE = "exchange",
  EXCHANGE_STOCK = "exchange_stock",
}
export enum StoredCreditSource {
  CLIENT = "client",
  EXCHANGE = "exchange",
  EXCHANGE_STOCK = "exchange_stock",
}

export const ReturnAddressZodSchema = z.object({
  name: z.string().nullish(),
  address1: z.string(),
  address2: z.string().nullish(),
  city: z.string(),
  country: z.string().nullish(),
  country_code: z.string().nullish(),
  province: z.string().nullish(),
  province_code: z.string().nullish(),
  zip: z.string().nullish(),
  latitude: z.string().nullish(),
  longitude: z.string().nullish(),
  location: zExt.objectId().nullish(),
  first_name: z.string().nullish(),
  last_name: z.string().nullish(),
  company: z.string().nullish(),
  email: z.string().nullish(),
  phone: z.string().nullish(),
});

export type ReturnAddressZod = z.infer<typeof ReturnAddressZodSchema>;
export type ReturnAddress = {
  name?: string | null;
  address1: string;
  address2?: string | null;

  city: string;
  country?: string | null;
  country_code?: string | null;
  province?: string | null;
  province_code?: string | null;
  zip?: string | null;
  latitude?: string | null;
  longitude?: string | null;
  location?: string | null; // id of location in Redo for return location
  first_name?: string | null;
  last_name?: string | null;
  company?: string | null;
  email?: string | null;
  phone?: string | null;
  externalLocationId?: string | null;
};

export type Weight = { amount: string; unit: "oz" | "lbs" | "g" };
export type labelForm = {
  id: symbol;
  items: Product[];
  weight: Weight;
  returnToAddress: ReturnAddress | undefined;
  labelNote: string;
}[];

export enum ReturnTypes {
  STORE_CREDIT = "store_credit",
  REFUND = "refund",
  EXCHANGE = "exchange",
}

export type ErrorTag =
  | "label"
  | "instant-refund"
  | "pickup"
  | "in-store-return"
  | "claim-report"
  | "repair-invoice";

export enum RejectTypes {
  RETURN_PORTAL = "Return portal",
  MANUAL_REVIEW = "Manual review",
  PRE_SHIPMENT = "Pre-shipment",
  EXPIRED = "Expired",
  POST_SHIPMENT = "Post-shipment",
}

export enum ExchangeInvoiceReminderEmailTrigger {
  AUTOMATED = "AUTOMATED",
  MANUAL = "MANUAL",
}
export const ReturnWarningZodSchema = z.object({
  code: z.string(),
  type: z.enum([
    "label",
    "instant-refund",
    "pickup",
    "in-store-return",
    "claim-report",
  ]),
  customerMessage: z.string().optional(),
  systemMessage: z.string().optional(),
  error: z.string().optional(),
});

export interface ReturnWarning {
  code: string;
  type: ErrorTag;
  customerMessage?: string;
  systemMessage?: string;
  error?: string;
}

export const ShipmentZodSchema = z.object({
  _shipment: ShippingProviderShipmentZodSchema,
  _refund: z.any().nullish(),
  _refundError: z.any().nullish(),
  toAddress: TeamAddressZodSchema,
  fromAddress: TeamAddressZodSchema,
  parcel: z.object({
    length: z.number().optional(),
    width: z.number().optional(),
    height: z.number().optional(),
    weight: z.number(),
  }),
  isReturn: z.boolean(),
  mode: z.enum(["test", "production"]),
  pickup: z.any(),
  postage_label: z.string().nullish(),
  form_label: z.string().nullish(),
  shipmentGroupID: z.string().optional(),
});

export type ShipmentZod = z.infer<typeof ShipmentZodSchema>;
export interface Shipment {
  _shipment: ShippingProviderShipment;
  _refund?: any;
  _refundError?: any;
  toAddress: Address;
  fromAddress: Address;
  parcel: { length?: number; width?: number; height?: number; weight: number };
  isReturn: boolean;
  mode: "test" | "production";
  pickup: any;
  postage_label?: string;
  form_label?: string;
  shipmentGroupID?: string;
}

// If you update this object, update MultipleChoiceAnswerZodSchema as well.
export interface MultipleChoiceAnswer {
  result: string[];
  question: string;
  questionText: string;
  answer: string;
}
export const MultipleChoiceAnswerZodSchema = z.object({
  result: z.array(z.string()).nullish(),
  question: z.string().nullish(),
  questionText: z.string(),
  answer: z.string(),
});

export const InputAnswerZodSchema = z.object({
  questionText: z.string(),
  inputType: z.string(),
  answer: z.string().nullish(),
  image: z.string().nullish(),
});

export const ExchangeForZodSchema = z.object({
  images: z.array(z.string()),
  product_id: z.string(),
  price: z.string(),
  option1: z.string().nullish(),
  option2: z.string().nullish(),
  option3: z.string().nullish(),
  variant_id: z.string(),
  sku: z.string().nullish(),
  variant_title: z.string(),
  product_title: z.string(),
});

export type ExchangeForZod = z.infer<typeof ExchangeForZodSchema>;

// If you update this object, update ExchangeGroupItemZodSchema as well.
export interface ExchangeGroupItem {
  productId: string;
  variantId: string;
  title: string;
  variantTitle: string;
  imageSrc: string;
  price?: string;
}

export const ExchangeGroupItemZodSchema = z.object({
  productId: z.string(),
  variantId: z.string(),
  title: z.string(),
  variantTitle: z.string(),
  imageSrc: z.string().nullish(),
  price: z.string().nullish(),
});

export type ExchangeGroupItemZod = z.infer<typeof ExchangeGroupItemZodSchema>;

export const ProductZodSchema = z.object({
  _id: zExt.objectId(),
  barcodeUrl: z.string().nullish(),
  exchange_for: ExchangeForZodSchema.nullish(),
  images: z.array(z.string().nullable()),
  variant_exists: z.boolean(),
  strategy: z.string(),
  status: z.nativeEnum(ReturnedProductStatus),
  quantity: z.number(),
  grams: z.number(),
  green_return: z.boolean(),
  damaged: z.boolean(),
  wrong_product: z.boolean(),
  reason: z.string().nullish(),
  condition: z.string(),
  order: z.union([z.any(), zExt.objectId()]),
  product_id: z.string().nullish(),
  price: z.string(),
  price_adjustment: z.string().nullish(),
  merchant_adjustment: z.string().nullish(),
  merchant_grade: z.string().nullish(),
  merchant_outcome: z.string().nullish(),
  merchant_notes: z.string().nullish(),
  item_disposition_audit_log: z
    .array(
      z.object({
        userId: zExt.objectId(),
        timestamp: z.date(),
        updates: z.object({
          merchant_grade: z.string().nullish(),
          merchant_outcome: z.string().nullish(),
          merchant_notes: z.string().nullish(),
        }),
      }),
    )
    .nullish(),
  hasBeenRestockedOnPlatform: z.boolean().nullish(),
  sku: z.string().nullish(),
  option1: z.string().nullish(),
  option2: z.string().nullish(),
  option3: z.string().nullish(),
  variant_id: z.string().nullish(),
  variant_title: z.string(),
  product_title: z.string(),
  line_item_id: z.string(),
  notes: z.string().nullish(),
  cancelled: z.boolean().nullish(),
  rejectMessage: z.string().nullish(),
  isManualReview: z.boolean(),
  manualReviewReason: z.string().nullish(),
  isFlagged: z.boolean(),
  flaggedReason: z.string().nullish(),
  originalVariantOptions: z
    .object({
      strategy: z.string(),
      exchange_for: ExchangeForZodSchema.nullish(),
      price_adjustment: z.string().nullish(),
      exchangeGroup: ExchangeGroupZodSchema.nullish(),
      exchangeGroupItem: ExchangeGroupItemZodSchema.nullish(),
    })
    .nullish(),
  multipleChoiceAnswers: z.array(MultipleChoiceAnswerZodSchema).nullish(),
  inputAnswers: z.array(InputAnswerZodSchema).nullish(),
  exchangeGroup: ExchangeGroupZodSchema.nullish(),
  exchangeGroupItem: ExchangeGroupItemZodSchema.nullish(),
  shipmentGroupID: z.string().nullish(),
  shipmentGroupIDs: z.array(z.string()).nullish(),
  hsCode: z.string().nullish(),
  dropshipSupplierName: z.string().nullish(),
});

export type ProductZod = z.infer<typeof ProductZodSchema>;

// If you update this object, update ProductZodSchema as well.
// If you can use ProductZodSchema, you should.
export interface Product {
  _id: string;
  barcodeUrl?: string;
  exchange_for?: {
    images: string[];
    product_id: string;
    price: string;
    option1: string | null;
    option2: string | null;
    option3: string | null;
    variant_id: string;
    sku?: string;
    variant_title: string;
    product_title: string;
  };
  images: string[];
  variant_exists: boolean;
  strategy: string;
  status: ReturnedProductStatus;
  quantity: number;
  grams: number;
  green_return: boolean;
  damaged: boolean;
  wrong_product: boolean;
  id: string;
  reason: string;
  condition: string;
  order: Order;
  product_id: string;
  price: string;
  price_adjustment?: string;
  merchant_adjustment?: string;
  merchant_grade?: string;
  merchant_outcome?: string;
  merchant_notes?: string;
  item_disposition_audit_log?: {
    userId: string;
    timestamp: Date;
    updates: {
      merchant_grade?: string;
      merchant_outcome?: string;
      merchant_notes?: string;
    };
  }[];
  hasBeenRestockedOnPlatform?: boolean;
  sku: string;
  option1: string | null;
  option2: string | null;
  option3: string | null;
  variant_id: string;
  variant_title: string;
  product_title: string;
  line_item_id: string;
  notes: string;
  cancelled: boolean;
  rejectMessage?: string;
  isManualReview: boolean;
  manualReviewReason?: string;
  isFlagged: boolean;
  flaggedReason?: string;
  originalVariantOptions?: {
    strategy: string;
    exchange_for?: {
      images: string[];
      product_id: string;
      price: string;
      option1: string | null;
      option2: string | null;
      option3: string | null;
      variant_id: string;
      sku?: string;
      variant_title: string;
      product_title: string;
    };
    price_adjustment?: string;
    exchangeGroup?: ExchangeGroup;
    exchangeGroupItem?: ExchangeGroupItem;
  };
  multipleChoiceAnswers?: MultipleChoiceAnswer[];
  exchangeGroup?: ExchangeGroup;
  exchangeGroupItem?: ExchangeGroupItem;
  shipmentGroupID?: string;
  exchange_properties: Json;
  // This is for products that have multiple labels. they belong to multiple shipments
  // currently only used fro create shipments modal. otherwise use
  // shipmentGroupID
  shipmentGroupIDs?: string[];
  isVariantExchange: () => boolean;
  hsCode?: string;
  dropshipSupplierName?: string;
  outOfStockDuringProcessing?: boolean;
  deliveredDate?: string;
}

interface Metadata {
  key: string;
  value: string;
}

export const MetadataZodSchema = z.object({
  key: z.string(),
  value: z.string(),
});

export const CustomerSummaryZodSchema = z.object({
  first_name: z.string().nullish(),
  last_name: z.string().nullish(),
  email: z.string().nullish(),
  phone_number: z.string().nullish(),
});

export const FulfillmentZodSchema = z.object({
  status: z.string(),
  created: z.date().nullish(),
});

export const CreditSourceZodSchema = z.enum([
  "client",
  "exchange",
  "exchange_stock",
]);

export const ShipmentGroupZodSchema = z.object({
  id: z.string(),
  address: ReturnAddressZodSchema,
  grams: z.number().nullish(),
  labelInstructions: z.string().nullish(),
});
export interface ShipmentGroup {
  id: string;
  address: ReturnAddress;
  grams?: number;
  labelInstructions?: string;
}

export const TotalsZodSchema = z.object({
  refund: z.number().nullish(),
  storeCredit: z.number().nullish(),
  greenReturnCredit: z.number().nullish(),
  fee: z.number(),
  returnCollectionHoldAmount: z.number().nullish(),
  depositRefunded: z.boolean().nullish(),
  charge: z.number().nullish(),
  repair: z.number().nullish(),
});
export interface Totals {
  refund?: number;
  storeCredit?: number;
  greenReturnCredit?: number;
  fee: number;
  returnCollectionHoldAmount?: number;
  depositRefunded?: boolean;
  charge?: number;
  repair?: number;
}

export enum Channel {
  SHOP_APP = "shopApp",
  POINT_OF_SALE = "pointOfSale",
  WEB = "web",
}
export const ChannelZod = z.nativeEnum(Channel);

export const OrderSummaryZodSchema = z.object({
  name: z.string(),
  number: z.union([z.string(), z.number()]).nullish(),
  order: zExt.objectId(),
});

export interface OrderSummary {
  name: string;
  number?: number;
  order: string;
}

export const NotesZodSchema = z.object({
  notesToCustomer: z.string().nullish(),
});

/**
 * Represents notes related to the return life cycle.
 * Add additional notes if needed as the return process evolves.
 */
export interface Notes {
  /**
   * A note from the merchant intended for the customer.
   * This field can be used to provide information or updates related to the return.
   */
  notesToCustomer?: string;
}

export const DiscountAllocationZodSchema = z.object({
  discountedAmount: z.object({ amount: z.string() }),
});
export interface DiscountAllocation {
  discountedAmount: { amount: string };
}

export const OriginExchangeProductType = z.nativeEnum(
  OriginExchangeProductTypeEnum,
);

const BaseOriginExchangeProductZodSchema = z.object({
  type: OriginExchangeProductType,
  newProductId: z.string(),
  newVariantId: z.string(),
});

const OriginVariantExchangeProductZodSchema =
  BaseOriginExchangeProductZodSchema.extend({
    type: z.literal(OriginExchangeProductTypeEnum.VARIANT),
    lineItemId: z.string(),
    previousVariantId: z.string(),
    isUnbundled: z.boolean(),
    price: z.string(),
    tax: z.string(),
  });

const OriginAdvancedExchangeProductZodSchema =
  BaseOriginExchangeProductZodSchema.extend({
    type: z.literal(OriginExchangeProductTypeEnum.ADVANCED),
    discountPrice: z.string(),
    price: z.string(),
    tax: z.string(),
  });

const OriginExchangeProductZodSchema = z.union([
  OriginVariantExchangeProductZodSchema,
  OriginAdvancedExchangeProductZodSchema,
]);

export const OriginLineItemZodSchema = z.object({
  id: z.string(),
  variantId: z.string(),
  discountPrice: z.string(),
  price: z.string(),
  tax: z.string(),
});

export const OriginReturnZodSchema = z.object({
  exchangeProducts: z.array(OriginExchangeProductZodSchema),
  originOrder: z.lazy(() => OriginOrderZodSchema),
  totalAdjustments: z.number(),
});

export const OriginOrderZodSchema: z.ZodType<OriginOrder> = z.object({
  isExchangeOrder: z.boolean(),
  originReturn: OriginReturnZodSchema.optional(),
  lineItems: z.array(OriginLineItemZodSchema),
});

export const MaybeOriginOrderZodSchema = z.union([
  OriginOrderZodSchema,
  z.object({ error: z.string() }),
]);

export const MaybeOriginOrderArrayZodSchema: z.ZodType<MaybeOriginOrderArray> =
  z.tuple([MaybeOriginOrderZodSchema]).rest(MaybeOriginOrderZodSchema);

export const ReturnZodSchema = ITrackableZodSchema.extend({
  _id: zExt.objectId(),
  createdAt: z.date(),
  updatedAt: z.date(),
  loopId: z.number(),
  idempotencyKey: z.string().nullish(),
  metadata: z.array(MetadataZodSchema),
  status: z.nativeEnum(ReturnStatus),
  allProductsDispositionCompleted: z.boolean().nullish(),
  customer: CustomerSummaryZodSchema.nullish(),
  fulfillment: FulfillmentZodSchema,
  processed: z.object({
    refunds: z.boolean(),
    storeCredit: z.boolean(),
    exchanges: z.boolean(),
  }),
  provision: z.nativeEnum(ProvisionType),
  refunds: z.array(z.any()),
  storedCredits: z.array(
    z.object({
      createdAt: z.date(),
      updatedAt: z.date(),
      priceRuleId: z.string().nullish(),
      discountCodeId: z.string().nullish(),
      source: CreditSourceZodSchema.nullish(),
      products: z.array(zExt.objectId()).nullish(),
      value: z.number().nullish(),
      greenReturn: z.boolean(),
    }),
  ),
  channel: ChannelZod.optional(),
  giftCards: z.array(
    z.object({
      createdAt: z.date(),
      updatedAt: z.date(),
      giftCardId: z.string().nullish(),
      code: z.string().nullish(),
      source: CreditSourceZodSchema.nullish(),
      products: z.array(z.string()).nullish(),
      value: z.number().nullish(),
      greenReturn: z.boolean(),
    }),
  ),
  products: z.array(ProductZodSchema),
  barcodes: z.array(z.string()).nullish(),
  cancelled_products: z.array(ProductZodSchema).nullish(),
  shipment: ShipmentZodSchema.optional(),
  shipments: z.array(ShipmentZodSchema),
  reShipments: z.array(ShipmentZodSchema).nullish(),
  shipmentGroups: z.array(ShipmentGroupZodSchema).nullish(),
  paymentIntents: z.array(PaymentIntentItemZodSchema),
  exchangeOrder: z.array(z.any()),
  exchangeShippingName: z.string().nullish(),
  draftOrderId: z.number().nullish(),
  draftOrderURL: z.string().optional(),
  markedForManualReview: z.boolean().optional(),
  postage_label: z.string().nullish(),
  form_label: z.string().nullish(),
  totals: TotalsZodSchema,
  isFlatShipping: z.boolean().nullish(),
  returnMethod: ReturnMethodZodSchema.nullish(),
  greenReturn: z
    .object({
      discount: z
        .object({
          amount: z.number(),
          discountCodeId: z.string().nullish(),
          priceRuleId: z.number().nullish(),
          code: z.string().nullish(),
        })
        .nullish(),
      refund: z.object({ amount: z.number().nullish() }).nullish(),
    })
    .nullish(),
  stripe: z
    .object({
      payment_token: z.string().nullish(),
      customer_id: z.string().nullish(),
    })
    .nullish(),
  instantExchangeRecovery: z
    .object({
      stripeInfo: z.object({
        customer_id: z.string().nullish(),
        payment_token: z.string().nullish(),
        setup_intent: zExt.objectId().nullish(),
      }),
      attempts: z.array(
        z.object({ createdAt: z.date(), successful: z.boolean() }),
      ),
    })
    .nullish(),
  labelDeductedFromCredit: z.boolean().nullish(),
  shippingFeeToDeduct: z.number().nullish(),
  timeline: z.array(TimelineEventZodSchema),
  team: zExt.objectId(),
  options: z.object({
    sendNotifications: z.boolean(),
    waiveDraftFee: z.boolean(),
  }),
  address: ReturnAddressZodSchema.nullish(),
  shipping_address: ReturnAddressZodSchema.nullish(),
  newOrderAddress: ReturnAddressZodSchema.nullish(),
  merchant_address: ReturnAddressZodSchema.nullish(),
  freeNewOrderShipping: z.boolean(),
  damaged: z.boolean(),
  wrongProduct: z.boolean(),
  shopifyReturnId: z.number().nullish(),
  shopifyReturnIds: z.array(z.number()).nullish(),
  netsuiteReturnAuthorization: z.object({ id: z.number().nullish() }).nullish(),
  notes: NotesZodSchema.nullish(),
  orders: z.array(OrderSummaryZodSchema),
  type: z.enum(["return", "claim", "warranty", "managed_claim"]),
  returnType: z.array(z.nativeEnum(CompensationMethod)),
  analyticsTotals: z
    .object({
      orderName: z.string().nullish(),
      returnValueNoTaxAdjustment: z.number().nullish(),
      returnTax: z.number().nullish(),
      returnAdjustment: z.number().nullish(),
      returnTotalValue: z.number().nullish(),
      returnExchangeValue: z.number().nullish(),
      returnStoreCreditValue: z.number().nullish(),
      returnRefundValue: z.number().nullish(),
      containsGreenReturn: z.boolean().nullish(),
    })
    .nullish(),
  operations: z
    .object({
      processedBy: z.string().nullish(),
      processedAt: z.date().nullish(),
      processedEmail: z.string().nullish(),
      approvedBy: z.string().nullish(),
      approvedAt: z.date().nullish(),
      approvedEmail: z.string().nullish(),
      createdBy: z.string().nullish(),
      createdEmail: z.string().nullish(),
    })
    .nullish(),
  rejectType: z.nativeEnum(RejectTypes).nullish(),
  originalReturnOptions: z
    .object({
      advancedExchangeItems: z.array(ExchangeItemZodSchema),
      new_order_taxes: z.string(),
      discount_allocations: z.array(DiscountAllocationZodSchema),
    })
    .nullish(),
  processAt: z
    .object({
      exchange: z.date().nullish(),
      storeCredit: z.date().nullish(),
      refund: z.date().nullish(),
    })
    .nullish(),
  sentInvoice: z.boolean(),
  completedAt: z.date().nullish(),
  pickup: z
    .intersection(
      z.object({
        email: z.string().nullish(),
        phone: z.string().nullable().nullish(),
        pickupDate: z.string(),
        pickupLocation: z.object({
          packageLocation: z.nativeEnum(PickupLocation),
          specialInstructions: z.string().nullish(),
        }),
        surveySent: z.boolean().nullish(),
        pickupPayer: z.string().optional(),
      }),
      z.record(z.any()),
    )
    .nullish(),
  pickupMetrics: z
    .object({
      eligible: z.boolean().nullish(),
      normalLabel: z.number().nullish(),
      rate: z.number().nullish(),
      coveredByRedo: z.boolean().nullish(),
      isMobile: z.boolean().nullish(),
    })
    .nullish(),
  inStoreReturn: z.boolean().optional(),
  settlement: SettlementZodSchema.nullish(),
  instantRefund: z
    .object({
      astra_user_id: z.string(),
      astraRoutine: z.any().nullish(),
      astraRoutines: z.array(z.any()),
      astraRoutinePullback: z.any().nullish(),
      status: z.nativeEnum(InstantRefundStatus),
      sandbox: z.boolean(),
    })
    .nullish(),
  lastEmailReminder: z.date().nullish(),
  warnings: z.array(ReturnWarningZodSchema).optional(),
  advancedExchangeItems: z.array(ExchangeItemZodSchema),
  new_order_taxes: z.string(),
  discount_allocations: z.array(DiscountAllocationZodSchema),
  usePreDiscountPrice: z.boolean(),
  expirationDate: z.date(),
  filedWithCarrier: z.date().nullish(),

  some_processed: z.boolean(),
  currentEmailFlows: z
    .array(
      z.object({
        emailFlowId: z.string(),
        currentStep: z.number(),
        continueDate: z.string(),
        fulfillmentId: z.string().optional(),
      }),
    )
    .optional(),
  testId: z.string().nullish(),
  treatmentId: z.string().nullish(),
  pickupVariant: z.string().nullish(),
  rejectReason: z.string().nullish(),
  currency: z.nativeEnum(Currency).nullish(),
  presentmentCurrency: z.nativeEnum(Currency).nullish(),
  happyReturnsData: z
    .object({
      rmaId: z.string().nullish(),
      qrCode: z.string().nullish(),
      retailerId: z.string().nullish(),
    })
    .nullish(),
  provider: z.nativeEnum(Provider).nullish(),
  customerPortalVersion: z.nativeEnum(CustomerPortalVersion).nullish(),
  agentReview: ReviewResultSchema.nullish(),
  agentSummary: ReturnSummaryResultSchema.nullish(),
});

export type ZodReturn = z.infer<typeof ReturnZodSchema>;

//If you update this object, update ReturnZodSchema as well.
//If you can use ReturnZodSchema, you should.
export interface Return extends ITrackable {
  _id: string;
  deletedAt?: string;
  createdAt: string;
  updatedAt: string;

  loopId: number;
  idempotencyKey?: string;
  metadata: Metadata[];
  status: ReturnStatus;
  allProductsDispositionCompleted?: boolean;
  customer?: CustomerSummary;
  fulfillment: Fulfillment;
  processed: { refunds: boolean; storeCredit: boolean; exchanges: boolean };
  provision: ProvisionType;
  /** The refund object from Shopify */
  refunds: any[];
  storedCredits: {
    createdAt: string;
    updatedAt: string;

    priceRuleId?: string;
    discountCodeId?: string;
    source?: StoredCreditSource;
    products?: string;
    value?: number;
    greenReturn: boolean;
  }[];
  giftCards: {
    createdAt: string;
    updatedAt: string;

    giftCardId?: string;
    code?: string;
    source?: GiftCardSource;
    products?: string[];
    value?: number;
    greenReturn: boolean;
  }[];
  products: Product[];
  barcodes?: string[];
  cancelled_products?: Product[];
  shipment?: Shipment;
  shipments: Shipment[];
  reShipments?: Shipment[];
  shipmentGroups?: ShipmentGroup[];
  paymentIntents?: PaymentIntentItem[];
  exchangeOrder: any[];
  exchangeShippingName?: string;
  draftOrderId?: number;
  draftOrderURL?: string;
  markedForManualReview?: boolean;
  postage_label?: string;
  packingSlipLink?: string;
  form_label?: string;
  totals: Totals;
  isFlatShipping?: boolean;
  returnMethod?: ReturnMethod;
  greenReturn?: {
    discount?: {
      amount: number;
      discountCodeId?: string;
      priceRuleId?: number;
      code?: string;
    };
    refund?: { amount?: number };
  };
  stripe?: { payment_token?: string; customer_id?: string };
  instantExchangeRecovery?: {
    stripeInfo: {
      customer_id?: string;
      payment_token?: string;
      setup_intent?: string;
    };
    attempts: { createdAt: Date; successful: boolean }[];
  };
  labelDeductedFromCredit?: boolean;
  shippingFeeToDeduct?: number;
  timeline: TimelineEvent[];
  team: Team;
  options: { sendNotifications: boolean; waiveDraftFee: boolean };
  address?: ReturnAddress;
  shipping_address?: ReturnAddress;
  newOrderAddress?: ReturnAddress;
  merchant_address?: ReturnAddress;
  freeNewOrderShipping: boolean;
  damaged: boolean;
  wrongProduct: boolean;
  shopifyReturnId?: number;
  shopifyReturnIds?: number[];
  netsuiteReturnAuthorization?: { id?: number };
  notes?: Notes;
  orders: OrderSummary[];
  type: ReturnType;
  returnType: CompensationMethod[];
  /** FOR ANALYTICS ONLY - DON'T USE FOR BUSINESS LOGIC */
  analyticsTotals?: {
    orderName?: string;
    returnValueNoTaxAdjustment?: number;
    returnTax?: number;
    returnAdjustment?: number;
    returnTotalValue?: number;
    returnExchangeValue?: number;
    returnStoreCreditValue?: number;
    returnRefundValue?: number;
    containsGreenReturn?: boolean;
  };
  operations?: {
    processedBy?: string;
    processedAt?: Date;
    processedEmail?: string;
    approvedBy?: string;
    approvedAt?: Date;
    approvedEmail?: string;
    createdBy?: string;
    createdEmail?: string;
  };
  rejectType?: RejectTypes;
  originalReturnOptions?: {
    advancedExchangeItems: ExchangeItem[];
    new_order_taxes: string;
    discount_allocations: DiscountAllocation[];
  };
  processAt?: { exchange?: Date; storeCredit?: Date; refund?: Date };
  sentInvoice: boolean;
  sentInvoiceReminders: { sentAt: Date }[];
  completedAt?: Date;
  closedWithNoAction?: boolean;
  // This object is not very consistent in the database
  pickup?: Record<string, any> & {
    email?: string;
    phone?: string | null;
    pickupDate: string;
    pickupLocation: {
      packageLocation: PickupLocation;
      specialInstructions: string;
    };
    surveySent?: boolean;
    pickupPayer: string;
  };
  pickupMetrics?: {
    eligible?: boolean;
    normalLabel?: number;
    rate?: number;
    coveredByRedo?: boolean;
    isMobile?: boolean;
  };
  inStoreReturn?: boolean;
  settlement?: Settlement;
  instantRefund: {
    astra_user_id: string;
    /** @deprecated // TODO Delete after astraRoutines migration */
    astraRoutine?: any;
    astraRoutines: any[];
    astraRoutinePullback?: any;
    status: InstantRefundStatus;
    sandbox: boolean;
  } | null;
  lastEmailReminder: Date | null;
  warnings: ReturnWarning[];
  advancedExchangeItems: ExchangeItem[];
  new_order_taxes: string;
  discount_allocations: DiscountAllocation[];
  usePreDiscountPrice: boolean;
  expirationDate: Date;
  filedWithCarrier: Date | null;

  // Virtuals
  some_processed: boolean;

  // Added in schema.methods.json() method
  /** _id, converted from ObjectId to string */
  id: string;
  currentEmailFlows?: {
    emailFlowId: string;
    currentStep: number;
    continueDate: string;
    fulfillmentId?: string;
  }[];
  testId?: string;
  treatmentId?: string;
  pickupVariant?: string;
  rejectReason?: string;
  currency?: Currency | null;
  presentmentCurrency?: Currency | null;
  happyReturnsData?: {
    rmaId?: string;
    qrCode?: string;
    retailerId?: string;
  } | null;
  provider?: Provider | null;
  customerPortalVersion?: CustomerPortalVersion | null;
  reportedClaims?: { claimId: string; claimRetrievalCode: string }[];
  repair?: { invoiceId?: string; invoiceURL?: string; shopifyOrderId?: string };
  refundOriginalShipping: boolean;
  refundOriginalShippingAmount?: number;
  originOrders: MaybeOriginOrderArray;
  invoiceStatus: InvoiceStatus;
  agentReview?: ReviewResult;
  agentSummary?: ReturnSummaryResult;
}

export enum InvoiceStatus {
  COMPLETE = "complete",
  PENDING = "pending",
}

export interface ShopifyVariant {
  sku: string;
  product_id: number;
}
export interface ShopifyVariantRest {
  id: number;
  variant_id: number;
  product_id: number;
  title: string;
  price: string;
  sku: string;
  position: number;
  inventory_policy: string;
  compare_at_price: string | null;
  created_at: string;
  updated_at: string;
  taxable: boolean;
  barcode: string | null;
  option1: string | null;
  option2: string | null;
  option3: string | null;
  fulfillment_service: string;
  grams: number;
  inventory_management: string | null;
  requires_shipping: boolean;
  weight: number | null;
  weight_unit: string;
  inventory_item_id: number;
  inventory_quantity: number;
  old_inventory_quantity: number;
  admin_graphql_api_id: string;
  image_id: number | null;
}

export interface ImageRest {
  id: number;
  alt: string | null;
  position: number;
  product_id: number;
  created_at: string;
  updated_at: string;
  admin_graphql_api_id: string;
  width: number | null;
  height: number | null;
  src: string;
  variant_ids: number[];
}

export interface ShopifyProductRest {
  id: number;
  title: string;
  body_html: string;
  vendor: string;
  product_type: string;
  created_at: string;
  handle: string;
  updated_at: string;
  published_at: string;
  template_suffix: string;
  published_scope: string;
  tags: string;
  status: string;
  admin_graphql_api_id: string;
  variants: ShopifyVariantRest[] | undefined;
  options: {
    id: number;
    product_id: number;
    name: string;
    position: number;
    values: string[];
  }[];
  images: ImageRest[] | undefined;
  image: ImageRest | undefined;
}

export interface NewItem {
  isVariantExchange: boolean;
  images?: string[];
  price?: string;
  itemValue?: string;
  tax?: string;
  variantTitle?: string;
  variantId?: string;
  title?: string;
  shopifyVariant?: ShopifyVariant;
  quantity?: number;
  attributes?: Metadata[];
  outOfStock?: boolean;
  option1?: string;
  option2?: string;
  option3?: string;
}

export const RESTOCK_STRATEGY: { [key: string]: boolean } = {
  exchange: true,
  refund: true,
  store_credit: true,
  repair: false,
};

export interface LineItemAnswers {
  lineItemId: string;
  answers: MultipleChoiceAnswer[];
}

/**
 * The merchant-server does some post-processing after retrieving the Return from the database
 * and adds more properties. This type represents the changes made.
 */
export interface MerchantAppReturn
  extends Omit<
    Return,
    "storedCredits" | "advancedExchangeItems" | "originalReturnOptions"
  > {
  // New properties for elements of existing arrays
  storedCredits: (Return["storedCredits"][number] & { code: string })[];
  advancedExchangeItems: MerchantAppExchangeItem[];
  originalReturnOptions?: Omit<
    NonNullable<Return["originalReturnOptions"]>,
    "advancedExchangeItems"
  > & { advancedExchangeItems: MerchantAppExchangeItem[] };

  // New properties
  exchanges: any[];
  draftOrder?: any;
  customerOrders: Order[];
  customerReturns: Return[];
  originOrders: MaybeOriginOrderArray;
}

/**
 * Shorthand type - represents the changes the merchant-app makes to Return.advancedExchangeItems
 * @see MerchantAppReturn
 */
type MerchantAppExchangeItem = Return["advancedExchangeItems"][number] & {
  shopifyVariant?: ShopifyVariant;
  outOfStockDuringProcessing?: boolean;
};

/**
 * Purchase value
 */
export function returnOriginalValue(return_: Return): number {
  return sumBy(return_.products || [], (product: Product) => +product.price);
}

export function returnOriginalPrice(return_: Return): number {
  return sumBy(
    return_.products || [],
    (product: Product) =>
      +(
        product.order.shopify.line_items.find(
          (lineItem: any) =>
            String(lineItem.id) === String(product.line_item_id),
        )?.price || product.price
      ),
  );
}

export function productTax(product: Product): number {
  let taxValue = 0.0;
  const shopifyLineItem = product.order.shopify.line_items.find(
    (lineItem: any) => String(lineItem.id) === String(product.line_item_id),
  );
  const taxLines = shopifyLineItem?.tax_lines || [];
  for (const taxLine of taxLines) {
    taxValue += parseFloat(taxLine.price) / (shopifyLineItem?.quantity || 1);
  }

  return taxValue;
}

export function returnTax(return_: Return): number {
  let taxValue = 0.0;
  const products = return_.products || [];
  for (const product of products) {
    taxValue += productTax(product);
  }
  return parseFloat(taxValue.toFixed(2));
}

export function returnDiscounts(return_: Return): number {
  let discountValue = 0.0;
  const products = return_.products || [];
  for (const product of products) {
    const lineItem = product.order.shopify.line_items.find(
      (lineItem: any) => String(lineItem.id) === String(product.line_item_id),
    );
    const discountAllocations = lineItem?.discount_allocations;
    if (discountAllocations) {
      for (const allocation of discountAllocations) {
        discountValue += parseFloat(allocation.amount) / lineItem.quantity;
      }
    }
  }
  return parseFloat(discountValue.toFixed(2));
}

export function returnAdjustment(return_: Return): number {
  return sumBy(
    return_?.products || [],
    (product: Product) => +product.price_adjustment! || 0,
  );
}

export function returnExchangeValue(return_: Return): number {
  return (
    sumBy(return_.products || [], (product) =>
      product.exchange_for ? +product.price || 0 : 0,
    ) +
    sumBy(
      return_.advancedExchangeItems || [],
      (product) => +(product.price || "0"),
    )
  );
}

export function isDamaged(returnReason?: string | null): boolean {
  if (!returnReason) {
    return false;
  }
  const damaged =
    returnReason.toLowerCase().includes("damage") ||
    returnReason.toLowerCase().includes("broken") ||
    returnReason.toLowerCase().includes("defective");

  return damaged;
}

export function isWrongProduct(returnReason?: string | null): boolean {
  if (!returnReason) {
    return false;
  }
  // Some merchants differentiate between 'Purchased wrong product' and 'Received wrong product'.
  // We should not charge the merchant for 'Purchased wrong product'
  const wrongProduct =
    (returnReason.toLowerCase().includes("wrong product") ||
      returnReason.toLowerCase().includes("wrong item")) &&
    !(
      returnReason.toLowerCase().includes("purchased") ||
      returnReason.toLowerCase().includes("ordered")
    );

  return wrongProduct;
}

export type Returner = {
  firstName?: string | null;
  lastName?: string | null;
  name: string;
  email: string;
  phone?: string | null;
};

// If it's gift return, the returner's info will be in the shipping address
// and the original customer's info will be in the customer object, so favor
// shipping address info first.
export function getReturner(return_: {
  shipping_address?: Omit<ReturnAddress, "location"> | null;
  customer?: CustomerSummary | null;
}): Returner {
  const firstName =
    return_.shipping_address?.first_name || return_.customer?.first_name;
  const lastName =
    return_.shipping_address?.last_name || return_.customer?.last_name;
  const name = return_.shipping_address?.name || `${firstName} ${lastName}`;
  return {
    firstName,
    lastName,
    name,
    email:
      return_.shipping_address?.email ||
      return_.customer?.email ||
      "Unknown email",
    phone: return_.shipping_address?.phone || return_.customer?.phone_number,
  };
}

export function getBuyer(return_: {
  shipping_address?: Omit<ReturnAddress, "location"> | null;
  customer?: CustomerSummary | null;
}) {
  const firstName = return_.customer?.first_name;
  const lastName = return_.customer?.last_name;
  const name = `${firstName} ${lastName}`;
  return {
    firstName,
    lastName,
    name,
    email: return_.customer?.email,
    phone: return_.customer?.phone_number,
  };
}

export function isGiftReturn(return_: {
  shipping_address?: Omit<ReturnAddress, "location"> | null;
  customer?: CustomerSummary | null;
}): boolean {
  const returner = getReturner(return_);
  const buyer = getBuyer(return_);
  if (isDefined(buyer.email)) {
    return returner.email !== buyer.email;
  }
  return returner.phone !== buyer.phone;
}

export function shouldUseShopifyExchangeApi(
  shopifyIntegration: {
    exchangeOrderStrategy: ShopifyExchangeOrderStrategy;
  } | null,
  return_: {
    shipping_address?: Omit<ReturnAddress, "location"> | null;
    customer?: CustomerSummary | null;
  },
): boolean {
  // If gift return, we should put the items on a new order instead of on the original order
  // (i.e. we shouldn't use the Shopify Exchange API)
  return (
    shopifyIntegration?.exchangeOrderStrategy ===
      ShopifyExchangeOrderStrategy.RETURN && !isGiftReturn(return_)
  );
}

export function allProductsHaveDispositionCompleted(
  returnProducts: {
    merchant_grade?: string | null | undefined;
    merchant_outcome?: string | null | undefined;
  }[],
): boolean {
  return returnProducts.every(
    (product) => !!product.merchant_grade && !!product.merchant_outcome,
  );
}

export function canRestockItemsManually(orderProvider: Provider): boolean {
  return orderProvider === Provider.SHOPIFY;
}

export type ShouldBeAManagedClaimInput = {
  returnType: ReturnType;
  packageProtectionPlusEnabled: boolean;
};

export const shouldBeAManagedClaim = ({
  returnType,
  packageProtectionPlusEnabled,
}: ShouldBeAManagedClaimInput) => {
  return returnType === "claim" && packageProtectionPlusEnabled;
};

export const claimIsBeingAutoprocessed = ({
  returnType,
  packageProtectionPlusEnabled,
  reportedClaims,
}: ShouldBeAManagedClaimInput & {
  reportedClaims: Return["reportedClaims"];
}) => {
  return (
    shouldBeAManagedClaim({ returnType, packageProtectionPlusEnabled }) &&
    reportedClaims?.length
  );
};

export const allProductsAreRepair = (products?: ProductZod[]) => {
  return !!products?.every((product) => product.strategy === "repair");
};

/*
  Because nothing was typed when we use the REST API from shopify, we need to have some source of truth
  of what the refund looks like.
 */
export type EssentialRefundSchema = {
  refund: {
    id: number;
    refund_line_items: {
      line_item_id: number;
      subtotal: number;
      total_tax: number;
      subtotal_set: { presentment_money: { currency_code: string } };
    }[];
    transactions: { amount: string; currency: string; created_at: string }[];
  };
};

export type ReturnZod = z.infer<typeof ReturnZodSchema>;

export const DARK_LAUNCH_MERCHANT_LABEL_LOG_STAMP =
  " - Dark launch merchant labels - ";
