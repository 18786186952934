import { z } from "zod";

export const ReturnSummaryResultSchema = z.object({
  part1Summary: z
    .string()
    .describe(
      "The first high level summary of the return. This should explain common things like refund type, return type, return value, and new order balances.",
    ),
  part2Summary: z
    .string()
    .nullish()
    .describe(
      "The second summary of the return. This should go more in depth about the return, like describing any discounts or bonuses associated with it.",
    ),
  part3Summary: z
    .string()
    .nullish()
    .describe(
      "The third summary of the return. This should continue with detailed information about the return.",
    ),
});

export type ReturnSummaryResult = z.infer<typeof ReturnSummaryResultSchema>;
